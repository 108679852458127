import { createAsyncThunk } from "@reduxjs/toolkit";

import endpoints from "../endpoints";
import { sendRequest, logoutRemoveCookies } from "../requests-browser";
import cloud_error_code_pb from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";
import { LoginRequest, LoginTypeRequest, LoginTypeResponse } from "./types";

const CodeEnum = cloud_error_code_pb.CloudErrorCode.Enum;

export const authLoginTypes = createAsyncThunk<LoginTypeResponse, LoginTypeRequest>(
  "auth/login_types",
  async loginTypes => {
    return await sendRequest(endpoints.LOGIN_TYPES, loginTypes);
  }
);

export const authLogin = createAsyncThunk<void, LoginRequest>("auth/login", async loginRequest => {
  await sendRequest(endpoints.LOGIN, loginRequest);
});

export interface AuthenticateArg {
  loginCode: number;
  email?: string | undefined;
  user_uuid?: string | undefined;
  organization_uuid?: string | undefined;
}

export const authAuthenticate = createAsyncThunk(
  "auth/authenticate",
  async (arg: AuthenticateArg) => {
    try {
      await sendRequest(endpoints.AUTHENTICATE, {
        ...arg,
        deviceId: "cloud_api_client",
        clientKey: "web_js_client",
      });
    } catch (error: any) {
      if (error.code === 3200) {
        error.message = `[${error.code}]: Bad Login Code`;
      }
      throw error;
    }
  }
);

export const clearAuth = createAsyncThunk("auth/clear", async () => {});

export const authLogout = createAsyncThunk("auth/logout", async () => {
  await sendRequest(endpoints.LOGOUT);
  logoutRemoveCookies();
});

export const checkAuth = createAsyncThunk("auth/check", async () => {
  const user = await sendRequest(endpoints.GET_CURRENT_USER);
  return user.toObject();
});
