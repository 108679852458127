import { createAsyncThunk } from "@reduxjs/toolkit";
import field_mask_pb from "google-protobuf/google/protobuf/field_mask_pb";
import _ from "lodash";

import user_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";

import endpoints from "../endpoints";
import { sendRequest } from "../requests-browser";

import { UsersRequest, NewUser, UserUpdate } from "./types";

const createUpdateProto = (
  callback: (userProto: user_pb.User, updateMask: field_mask_pb.FieldMask) => void
) => {
  const userProto = new user_pb.User();
  const updateMask = new field_mask_pb.FieldMask();
  callback(userProto, updateMask);
  const updateProto = new user_pb.UpdateUserRequest();
  updateProto.setUser(userProto);
  updateProto.setUpdateMask(updateMask);
  return updateProto;
};

export const fetchUsers = createAsyncThunk("users/fetchAll", async (args: UsersRequest) => {
  const responseProto = await sendRequest(endpoints.GET_USERS, args);
  return responseProto.toObject();
});

export const fetchUser = createAsyncThunk("users/fetchOne", async (uuid: string) => {
  const user = await sendRequest(endpoints.GET_USER, { path: { identifier: uuid } });
  return user.toObject();
});

export const createUser = createAsyncThunk(
  "users/create",
  async ({ email, groups, firstName, lastName, organizationId, organizationPermission }: NewUser) => {
    const updateProto = createUpdateProto((userProto, updateMask) => {
      updateMask.setPathsList(["first_name", "last_name", "groups", "organization_id", "organization_permission", "enabled"]);
      userProto.setFirstName(firstName);
      userProto.setLastName(lastName);
      userProto.setEnabled(true);
      groups.forEach(groupName => {
        const groupProto = new user_pb.Group();
        groupProto.setName(groupName);
        userProto.addGroups(groupProto);
      });
      userProto.setOrganizationId(organizationId);
      userProto.setOrganizationPermission(organizationPermission);
    });
    const user = await sendRequest(endpoints.ADD_USER, {
      path: { identifier: email },
      protobuf: updateProto,
    });
    return user.toObject();
  }
);

export interface UpdateArgs {
  uuid: string;
  update: UserUpdate;
}

export const updateUser = createAsyncThunk("users/update", async ({ uuid, update }: UpdateArgs) => {
  const updateProto = createUpdateProto((userProto, updateMask) => {
    if ("firstName" in update) {
      updateMask.addPaths("first_name");
      if (update.firstName) userProto.setFirstName(update.firstName);
    }
    if ("lastName" in update) {
      updateMask.addPaths("last_name");
      if (update.lastName) userProto.setLastName(update.lastName);
    }
    if ("email" in update) {
      updateMask.addPaths("email");
      if (update.email) userProto.setEmail(update.email);
    }
    if ("active" in update) {
      updateMask.addPaths("active");
      if (update.active) userProto.setActive(update.active);
    }
    if ("enabled" in update) {
      updateMask.addPaths("enabled");
      if (update.enabled) userProto.setEnabled(update.enabled);
    }
    if ("emailStatus" in update) {
      updateMask.addPaths("email_status");
      if (update.emailStatus) userProto.setEmailStatus(update.emailStatus);
    }
    if ("organizationId" in update) {
      updateMask.addPaths("organization_id");
      if (update.organizationId) userProto.setOrganizationId(update.organizationId);
    }
    if ("organizationPermission" in update) {
      updateMask.addPaths("organization_permission");
      if (update.organizationPermission)
        userProto.setOrganizationPermission(update.organizationPermission);
    }
    if ("organizationNotes" in update) {
      updateMask.addPaths("organization_notes");
      if (update.organizationNotes) userProto.setOrganizationNotes(update.organizationNotes);
    }
    if ("groups" in update) {
      updateMask.addPaths("groups");
      update.groups?.forEach(groupName => {
        const groupProto = new user_pb.Group();
        groupProto.setName(groupName);
        userProto.addGroups(groupProto);
      });
    }
  });
  try {
    const user = await sendRequest(endpoints.UPDATE_USER, {
      path: { identifier: uuid },
      protobuf: updateProto,
    });
    return user.toObject();
  } catch (error: any) {
    console.error(error);
    alert(
      "Failed to update user. Check the console for more information. " +
        (error.code ? `Code: ${error.code}` : "")
    );
    throw error;
  }
});
